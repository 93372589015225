/*global kakao*/

import React, { Component } from 'react';
import axios from "axios";
import { AuthContext } from "../context/auth";
import { withRouter } from 'react-router-dom';
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Label,
    UncontrolledCollapse,
    ButtonToggle,
} from "reactstrap";
import "../App.css";
import geodetic from "ecef-projector"
class ZoneGridPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initial_pos: [33.50000300,	126.49687400],
            sidebar_open: false,
            data_bar_open: false,
            zone_grid: true,
            show_lrn: false,
            show_all_lrn: true,
            meas_dist: false,
            // TODO = load availdable zones & update
            zone_list: {
                "JEJU": [],
                "SEJN": [],
                "SEUL": [],
            },
            selected_area: "JEJU",
            selected_zone: "all",
            goto: {
                area: "JEJU",
                zone: "0"
            }
        };

        this.overlayOn = false;
        this.map = undefined;
        this.intervalId = undefined;
        this.data_array = [];
        this.activeRV = [false];
        this.input_position = undefined;
        // this.
        this.ZoneList = ["JEJU", "SEJN", "SEUL", "KATRI", "GYGI", "YECN", "KUSN", "PYTK", "BUSN","GANG"];
        this.ZoneData = [];
        
        this.zone_grid_line = [];
        this.zone_grid_overlay = [];

        // lrn path data (kakao polyline array)
        this.lrn_data = new Map();
        this.lrn_stroke_weight = 2;

        // data list
        this.data_list = ["LRN"];
        // for test
        this.temp_path = [];
        this.path_on_map = undefined;

        this.moveLine = null // 선이 그려지고 있을때 마우스 움직임에 따라 그려질 선 객체 입니다
        this.clickLine = null; // 마우스로 클릭한 좌표로 그려질 선 객체입니다
        this.distanceOverlay = null; // 선의 거리정보를 표시할 커스텀오버레이 입니다
        this.dots = {}; // 선이 그려지고 있을때 클릭할 때마다 클릭 지점과 거리를 표시하는 커스텀 오버레이 배열입니다.
    }
    static contextType = AuthContext;
    async componentDidMount() {
        // this.intervalId = await setInterval(() => this.getLatest(), 5000);
        this.getLatest();
        const script = document.createElement("script");
        
        script.async = true;
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=0fb56ea6c0eb9423465bb7b46d0295fc&autoload=false";
        document.head.appendChild(script);
        script.onload = () => {
            kakao.maps.load(() => {
                this.mapWrapper = document.getElementById("mapWrapper");
                this.container = document.getElementById("map");
                this.options = {
                    center: new kakao.maps.LatLng(this.state.initial_pos[0], this.state.initial_pos[1]),
                    level: 8
                };
                this.map = new window.kakao.maps.Map(this.container, this.options);
                if (this.map !== undefined) {
                    
                    this.updateView();
                }

                // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
                var mapTypeControl = new kakao.maps.MapTypeControl();

                // 지도 타입 컨트롤을 지도에 표시합니다
                this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

                this.rvWrapper = document.getElementById("rvWrapper");
                this.rvContainer = document.getElementById('roadview'); //로드뷰를 표시할 div
                this.rv = new kakao.maps.Roadview(this.rvContainer); //로드뷰 객체
                this.rvClient = new kakao.maps.RoadviewClient(); //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

                // 마커 이미지를 생성합니다.
                var markImage = new kakao.maps.MarkerImage(
                    'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
                    new kakao.maps.Size(26, 46),
                    {
                        // 스프라이트 이미지를 사용합니다.
                        // 스프라이트 이미지 전체의 크기를 지정하고
                        spriteSize: new kakao.maps.Size(1666, 168),
                        // 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
                        // background-position으로 지정하는 값이며 부호는 반대입니다.
                        spriteOrigin: new kakao.maps.Point(705, 114),
                        offset: new kakao.maps.Point(13, 46)
                    }
                );

                // 드래그가 가능한 마커를 생성합니다.
                this.rvMarker = new kakao.maps.Marker({
                    image : markImage,
                    position: new kakao.maps.LatLng(this.state.initial_pos[0], this.state.initial_pos[1]),
                    draggable: true
                });
                
                // 일반 마커 생성.
                this.marker = new kakao.maps.Marker(); 
                
                var rvContainer = document.getElementById('roadview');
                var rvWrapper = document.getElementById('rvWrapper');
                var rvClient = this.rvClient;
                var rvMarker = this.rvMarker;
                var mapWrapper = document.getElementById("mapWrapper");
                var map = this.map;
                var rv = this.rv;
                var activeRV = this.activeRV;
                
                //마커에 dragend 이벤트를 할당합니다
                var toggleRoadview = this.toggleRoadview;
                kakao.maps.event.addListener(this.rvMarker, 'dragend', function(mouseEvent) {
                    var position = rvMarker.getPosition(); //현재 마커가 놓인 자리의 좌표
                    // 전달받은 좌표(position)에 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄웁니다
                    toggleRoadview(position, mapWrapper, map, rvContainer, rv, rvClient, activeRV, rvWrapper);
                });

                //지도에 클릭 이벤트를 할당합니다
                kakao.maps.event.addListener(this.map, 'click', (mouseEvent) => {
                    // 현재 클릭한 부분의 좌표를 리턴 
                    var position = mouseEvent.latLng;
                    rvMarker.setPosition(position);   
                    // 전달받은 좌표(position)에 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄웁니다
                    toggleRoadview(position, mapWrapper, map, rvContainer, rv, rvClient, activeRV, rvWrapper);
                    // 지도 클릭이벤트가 발생했는데 선을 그리고있는 상태가 아니면
                    if(this.state.meas_dist)
                    {
                        if (!this.drawing_flag) {
                            // 상태를 true로, 선이 그리고있는 상태로 변경합니다
                            this.drawing_flag = true;
                            // 지도 위에 선이 표시되고 있다면 지도에서 제거합니다
                            this.deleteClickLine();                        
                            // 지도 위에 커스텀오버레이가 표시되고 있다면 지도에서 제거합니다
                            this.deleteDistnce();
                            // 지도 위에 선을 그리기 위해 클릭한 지점과 해당 지점의 거리정보가 표시되고 있다면 지도에서 제거합니다
                            this.deleteCircleDot();                    
                            // 클릭한 위치를 기준으로 선을 생성하고 지도위에 표시합니다
                            this.clickLine = new kakao.maps.Polyline({
                                map: map, // 선을 표시할 지도입니다 
                                path: [position], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
                                strokeWeight: 3, // 선의 두께입니다 
                                strokeColor: '#db4040', // 선의 색깔입니다
                                strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
                                strokeStyle: 'solid' // 선의 스타일입니다
                            });                      
                            // 선이 그려지고 있을 때 마우스 움직임에 따라 선이 그려질 위치를 표시할 선을 생성합니다
                            this.moveLine = new kakao.maps.Polyline({
                                strokeWeight: 3, // 선의 두께입니다 
                                strokeColor: '#db4040', // 선의 색깔입니다
                                strokeOpacity: 0.5, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
                                strokeStyle: 'solid' // 선의 스타일입니다    
                            });                  
                            // 클릭한 지점에 대한 정보를 지도에 표시합니다
                            this.displayCircleDot(position, 0);          
                        } else { // 선이 그려지고 있는 상태이면
                            // 그려지고 있는 선의 좌표 배열을 얻어옵니다
                            var path = this.clickLine.getPath();
                            // 좌표 배열에 클릭한 위치를 추가합니다
                            path.push(position);
                            // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
                            this.clickLine.setPath(path);
                            var distance = Math.round(this.clickLine.getLength());
                            this.displayCircleDot(position, distance);
                        }
                    }
                });

                // 이벤트가 발생할 때마다 로드뷰의 position값을 읽어, rvMarker에 반영 
                kakao.maps.event.addListener(rv, 'position_changed', function(){
                    var position = rv.getPosition();
                    rvMarker.setPosition(position);
                    map.setCenter(position);
                });
                kakao.maps.event.addListener(this.map, 'rightclick', (mousEvent) => {
                    // 지도 오른쪽 클릭 이벤트가 발생했는데 선을 그리고있는 상태이면
                    if (this.drawing_flag) {                        
                        // 마우스무브로 그려진 선은 지도에서 제거합니다
                        this.moveLine.setMap(null);
                        this.moveLine = null;  
                        
                        // 마우스 클릭으로 그린 선의 좌표 배열을 얻어옵니다
                        var path = this.clickLine.getPath();
                    
                        // 선을 구성하는 좌표의 개수가 2개 이상이면
                        if (path.length > 1) {
                
                            // 마지막 클릭 지점에 대한 거리 정보 커스텀 오버레이를 지웁니다
                            if (this.dots[this.dots.length-1].distance) {
                                this.dots[this.dots.length-1].distance.setMap(null);
                                this.dots[this.dots.length-1].distance = null;    
                            }
                
                            var distance = Math.round(this.clickLine.getLength()), // 선의 총 거리를 계산합니다
                                content = this.getTimeHTML(distance); // 커스텀오버레이에 추가될 내용입니다
                                
                            // 그려진 선의 거리정보를 지도에 표시합니다
                            this.showDistance(content, path[path.length-1]);  
                             
                        } else {
                
                            // 선을 구성하는 좌표의 개수가 1개 이하이면 
                            // 지도에 표시되고 있는 선과 정보들을 지도에서 제거합니다.
                            this.deleteClickLine();
                            this.deleteCircleDot(); 
                            this.deleteDistnce();
                
                        }
                        
                        // 상태를 false로, 그리지 않고 있는 상태로 변경합니다
                        this.drawing_flag = false;
                    }
                    else
                    {
                        var latlng = mousEvent.latLng;
                        var iwContent = '<div style="padding:15px;">'+latlng.getLat().toFixed(5)+','+latlng.getLng().toFixed(5)+'</div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
                        iwPosition = latlng, //인포윈도우 표시 위치입니다
                        iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다
                        var infowindow = new kakao.maps.InfoWindow({
                            map: map, // 인포윈도우가 표시될 지도
                            position : iwPosition, 
                            content : iwContent,
                            removable : iwRemoveable
                        });
                    }
                });
                kakao.maps.event.addListener(this.map, 'maptypeid_changed', () => {
                    // if()
                    let type_id = this.map.getMapTypeId();
                    let option;

                    if(type_id === kakao.maps.MapTypeId.SKYVIEW || type_id === kakao.maps.MapTypeId.HYBRID){
                        option = {
                            strokeColor: "#FFFF00",
                            strokeWeight: this.lrn_stroke_weight+1,
                        };
                        this.lrn_data.forEach((val) => {
                            if(Array.isArray(val)){
                                val.forEach((path)=>path.setOptions(option));
                            } else {
                                val.setOptions(option);
                            }
                        });
                    } else {
                        option = {
                            strokeColor: "#36f",
                            strokeWeight: this.lrn_stroke_weight,
                        };
                        this.lrn_data.forEach((val) => {
                            if(Array.isArray(val)){
                                val.forEach((path)=>path.setOptions(option));
                            } else {
                                val.setOptions(option);
                            }
                        });
                    }
                })
                kakao.maps.event.addListener(this.map, "zoom_changed", () => {
                    let level = this.map.getLevel();
                    if(level < 3 && this.lrn_stroke_weight !== 1){
                        this.lrn_stroke_weight = 1;
                        this.lrn_data.forEach((paths) => {
                            paths.setOptions({strokeWeight: this.lrn_stroke_weight})
                        })
                    } else if(this.lrn_stroke_weight !== 2){
                        this.lrn_stroke_weight = 2;
                        this.lrn_data.forEach((paths) => {
                            paths.setOptions({strokeWeight: this.lrn_stroke_weight})
                        })
                    }
                });
                kakao.maps.event.addListener(this.map, 'mousemove', (mouseEvent) => {
                    // 지도 마우스무브 이벤트가 발생했는데 선을 그리고있는 상태이면
                    if (this.drawing_flag){                        
                        // 마우스 커서의 현재 위치를 얻어옵니다 
                        var mousePosition = mouseEvent.latLng;                 
                        // 마우스 클릭으로 그려진 선의 좌표 배열을 얻어옵니다
                        var path = this.clickLine.getPath();                        
                        // 마우스 클릭으로 그려진 마지막 좌표와 마우스 커서 위치의 좌표로 선을 표시합니다
                        var movepath = [path[path.length-1], mousePosition];
                        this.moveLine.setPath(movepath);    
                        this.moveLine.setMap(map);                        
                        var distance = Math.round(this.clickLine.getLength() + this.moveLine.getLength()), // 선의 총 거리를 계산합니다
                            content = '<div class="dotOverlay distanceInfo">총거리 <span class="number">' + distance + '</span>m</div>'; // 커스텀오버레이에 추가될 내용입니다                        
                        // 거리정보를 지도에 표시합니다
                        this.showDistance(content, mousePosition);   
                    }             
                });                                 
            });
        };
        
    }

    // 클릭으로 그려진 선을 지도에서 제거하는 함수입니다
    deleteClickLine() {
        if (this.clickLine) {
            this.clickLine.setMap(null);    
            this.clickLine = null;        
        }
    }

    // 마우스 드래그로 그려지고 있는 선의 총거리 정보를 표시하거
    // 마우스 오른쪽 클릭으로 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 생성하고 지도에 표시하는 함수입니다
    showDistance(content, position) {
        
        if (this.distanceOverlay) { // 커스텀오버레이가 생성된 상태이면
            
            // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
            this.distanceOverlay.setPosition(position);
            this.distanceOverlay.setContent(content);
            
        } else { // 커스텀 오버레이가 생성되지 않은 상태이면
            
            // 커스텀 오버레이를 생성하고 지도에 표시합니다
            this.distanceOverlay = new kakao.maps.CustomOverlay({
                map: this.map, // 커스텀오버레이를 표시할 지도입니다
                content: content,  // 커스텀오버레이에 표시할 내용입니다
                position: position, // 커스텀오버레이를 표시할 위치입니다.
                xAnchor: 0,
                yAnchor: 0,
                zIndex: 3  
            });      
        }
    }

    // 그려지고 있는 선의 총거리 정보와 
    // 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 삭제하는 함수입니다
    deleteDistnce () {
        if (this.distanceOverlay) {
            this.distanceOverlay.setMap(null);
            this.distanceOverlay = null;
        }
    }

    // 선이 그려지고 있는 상태일 때 지도를 클릭하면 호출하여 
    // 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 표출하는 함수입니다
    displayCircleDot(position, distance) {

        // 클릭 지점을 표시할 빨간 동그라미 커스텀오버레이를 생성합니다
        var circleOverlay = new kakao.maps.CustomOverlay({
            content: '<span class="dot"></span>',
            position: position,
            zIndex: 1
        });

        // 지도에 표시합니다
        circleOverlay.setMap(this.map);

        if (distance > 0) {
            // 클릭한 지점까지의 그려진 선의 총 거리를 표시할 커스텀 오버레이를 생성합니다
            var distanceOverlay = new kakao.maps.CustomOverlay({
                content: '<div class="dotOverlay">거리 <span class="number">' + distance + '</span>m</div>',
                position: position,
                yAnchor: 1,
                zIndex: 2
            });

            // 지도에 표시합니다
            distanceOverlay.setMap(this.map);
        }

        // 배열에 추가합니다
        this.dots.push({circle:circleOverlay, distance: distanceOverlay});
    }

    // 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 지도에서 모두 제거하는 함수입니다
    deleteCircleDot() {
        var i;

        for ( i = 0; i < this.dots.length; i++ ){
            if (this.dots[i].circle) { 
                this.dots[i].circle.setMap(null);
            }

            if (this.dots[i].distance) {
                this.dots[i].distance.setMap(null);
            }
        }

        this.dots = [];
    }

    // 마우스 우클릭 하여 선 그리기가 종료됐을 때 호출하여 
    // 그려진 선의 총거리 정보와 거리에 대한 도보, 자전거 시간을 계산하여
    // HTML Content를 만들어 리턴하는 함수입니다
    getTimeHTML(distance) {

        // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
        var walkkTime = distance / 67 | 0;
        var walkHour = '', walkMin = '';

        // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
        if (walkkTime > 60) {
            walkHour = '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 '
        }
        walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

        // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
        var bycicleTime = distance / 227 | 0;
        var bycicleHour = '', bycicleMin = '';

        // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
        if (bycicleTime > 60) {
            bycicleHour = '<span class="number">' + Math.floor(bycicleTime / 60) + '</span>시간 '
        }
        bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

        // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
        var content = '<ul class="dotOverlay distanceInfo">';
        content += '    <li>';
        content += '        <span class="label">총거리</span><span class="number">' + distance + '</span>m';
        content += '    </li>';
        content += '    <li>';
        content += '        <span class="label">도보</span>' + walkHour + walkMin;
        content += '    </li>';
        content += '    <li>';
        content += '        <span class="label">자전거</span>' + bycicleHour + bycicleMin;
        content += '    </li>';
        content += '</ul>'

        return content;
    }

    makePath(kakao_array, options){
        return new kakao.maps.Polyline({
            path: kakao_array,
            strokeWeight: 2,
            strokeColor: '#36f',
            strokeOpacity: 0.8,
            // endArrow: true,
            ...options
        });
    }
    makePaths(kakao_array,options){
        let paths = [];
        paths = kakao_array.map((val) => {
            return new kakao.maps.Polyline({
                path: val,
                strokeWeight: 2,
                strokeColor: '#36f',
                strokeOpacity: 0.8,
                // endArrow: true,
                ...options
            });
        });
        return paths;
    }
    //temp(center, z){
    //    let color = "#FF0000";
    //    if(z < 0.002){
    //        color = "#000000";
    //    } else if(z < 0.01){
    //        color = "#0000FF";
    //    } else if(z < 0.02){
    //        color = "#00F5F5";
    //    } else if(z < 0.05){
    //        color = "#00FF00";
    //    } else {
    //        color = "#FF0000";
    //    }
    //    return new kakao.maps.Circle({
    //        map: this.map,
    //        center: center,
    //        radius: 1,
    //        strokeWeight: 1, // 선의 두께입니다 
    //        strokeColor: color, // 선의 색깔입니다
    //        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
    //        // strokeStyle: 'dashed', // 선의 스타일 입니다
    //        fillColor: "CFE7FF", // 채우기 색깔입니다
    //        fillOpacity: 0.7  // 채우기 불투명도 입니다   
    //    });
    //}
    //makeCircles(array, sub_array){
    //    return array.map((val, idx) => {
    //        return this.temp(val, sub_array.poses[idx].position.z);
    //    });
    //}

    async getLatest() {
        await axios.post("/api/get_active", {}).then(function (res) {
            if (res.statusText === "OK") {
                return {
                    vehicles: res.data.locations
                };
            }
            return { vehicles: {} };
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
        }).then((data) => this.setState(data));
    }

    async getData(area) {
        var zonefile = area+".zone";
        this.data_array = await axios.post("/api/get_data", {zonefile}).then(function (res) {
            var data_array = res.data.data;

            return(data_array);
        });
        this.updatePolygonPath(this.data_array);
        var data_array = this.data_array
        this.ZoneData.push({area:area, data:data_array})
    }

    async getLRNData(area, zone, id=0) {
        let key = area + zone;
        if(!!this.lrn_data.get(key)){
            console.log(key+" already exists");
            if(!window.confirm("Do you reload "+key+"?")){
                return;
            }
        }
        // if(!!this.lrn_data.get(key)){
            // if(Array.isArray(this.lrn_data.get(key))){
            //     this.lrn_data.get(key).forEach((path)=>path.setMap(null));
            // } else {
            //     this.lrn_data.get(key).setMap(null);
            // }
        //     this.lrn_data.delete(key);
        // }
        await axios.get("/api/get-lrn",{
            params: {
                area: area,
                zone: zone,
                id: id,
            }
        }).then((res)=>{
            if(res.data.is_valid){
                let paths = res.data.paths.map((path) => {
                    return path.poses.map((point) => {
                        let x = point.position.x * 180 / Math.PI, y = point.position.y * 180 / Math.PI;
                        return new kakao.maps.LatLng(x, y);
                    });
                });
                if(!!this.lrn_data.get(key)){
                    if(Array.isArray(this.lrn_data.get(key))){
                        this.lrn_data.get(key).forEach((path)=>path.setPath(paths));
                    } else {
                        this.lrn_data.get(key).setPath(paths);
                    }
                } else {
                    let map_path = this.makePath(paths, {strokeWeight: this.lrn_stroke_weight});
                    map_path.setMap(this.map);
                    this.lrn_data.set(key,map_path);
                }
                if (!this.state.zone_list[area].includes(zone)) {
                    this.state.zone_list[area].push(zone);
                    this.state.zone_list[area].sort((a, b) => Number(a) < Number(b));
                }
                this.forceUpdate(()=>{
                    document.getElementsByName("checkbox_"+key)[0].checked = this.lrn_data.get(key).getMap() === this.map;
                });
                // paths.map((path,i)=>this.makeCircles(path, res.data.paths[i]));
            }
        }).catch((err)=>{
            console.log(err);
            if(!!err.response.data){
                console.error(err.response.data.err);
            } else {
                console.error(err);
            }
        });
    }
    handleGoTo(area, zone){
        var ZoneData = this.ZoneData
        var ZoneNum = Number(zone);
        var zone_llh = [];
        for(let i=0; i<ZoneData.length; i++)
        {
            if(area == ZoneData[i].area)
            {
                if(ZoneData[i].data.length > 2+ZoneNum*2)
                {
                    zone_llh = [ZoneData[i].data[2+ZoneNum*2],ZoneData[i].data[2+ZoneNum*2+1]]
                    this.vpChange(zone_llh[0], zone_llh[1]);
                }
                break
            }
        }
    }

    handleLoadLRN(area, zone){
        if(zone === "all"){
            // TODO = Load all "available zones, skip empty zones
            for(let i = 0; i < 384; i++){
                this.getLRNData(area,i);
            }
        } else {
            this.getLRNData(area,zone);
        }
    }
    showDataList(toggle){
        let kakaoWrapper = document.getElementById("kakaoWrapper");
        let data_list = document.getElementById("dataCollapse");
        if(toggle){
            kakaoWrapper.style.width = "80%";
            data_list.style.width = "20%";
        } else {
            kakaoWrapper.style.width = "100%";  
            data_list.style.width = "0%";
        }
        this.setState({
            data_bar_open: toggle,
        });
        setTimeout(()=>{
            console.log("layout")
            this.map.relayout()
        },380);
    }
    handleChangePath(key, event){
        let checked = event.target.checked;
        let path = this.lrn_data.get(key)
        if(checked){
            path.setMap(this.map)
        } else {
            path.setMap(null);
        }
    }
    getDataList(){
        let list = [];
        this.lrn_data.forEach((data, key) => {
            if(list.indexOf(key) < 0)
                list.push(key);
        });
        list.sort((frame_a, frame_b) => {
            let a = Number(frame_a.replace(/[^0-9]/g,''));
            let b = Number(frame_b.replace(/[^0-9]/g,''));
            return a < b;
        });
        
        return(
            <div style={{fontSize:"2rem", padding:"5px", borderLeft:"1px solid #cdcdcd", overflow:"hidden", height:"100%"}}>
                <Navbar color="faded" light style={{display:"flex", justifyContent:"space-between", flexWrap:"nowrap", borderBottom:"1px solid #cdcdcd"}}>
                    <NavbarBrand >LRN</NavbarBrand>
                    <NavbarToggler onClick={()=>{
                        if(list.length >0){
                            this.setState({show_lrn: !this.state.show_lrn});
                        }}}/>
                </Navbar>
                
                <Collapse isOpen={this.state.show_lrn} style={{height: "88%"}}>
                    <Button className="datalist-btn" color="primary" style={{width:"100%", height: "2em"}}
                    onClick={(e)=>{
                            e.stopPropagation();
                            this.lrn_data.forEach((data, key)=>{
                                if(this.state.show_all_lrn){
                                    document.getElementsByName("checkbox_"+key)[0].checked = false;
                                    data.setMap(null);
                                } else {
                                    document.getElementsByName("checkbox_"+key)[0].checked = true;
                                    data.setMap(this.map);
                                }
                            });
                            this.setState({show_all_lrn: !this.state.show_all_lrn});
                        }}> All </Button>
                    <div style={{height: "inherit", overflow:"auto"}}>
                        {list.map((key,idx) => {
                            return (
                                <Label key={key} className="datalist-label sub1" >
                                    {key}
                                <Input name={"checkbox_"+key} type="checkbox" onChange={this.handleChangePath.bind(this, key)}/>
                                </Label>
                            );
                        })}
                    </div>
                </Collapse>
            </div>
        );
    }

    setGoToArea = e => {
        console.log("setGoToArea")
        let area = e.target.value;
        this.setState((state)=> state.goto.area = area);
        
    }
    setGoToZone = e => {
        console.log("setGoToZone")
        let zone = e.target.value;
        this.setState((state)=> state.goto.zone = zone);
    }
    setArea = e => {
        console.log("setArea")
        let area = e.target.value;
        this.setState((state)=>state.selected_area = area);
    }
    setZone = e => {
        console.log("setZone")
        let zone = e.target.value;
        this.setState((state)=>state.selected_zone = zone);
    }
    requestLogin = e=> {
        
    }

    setInputPosition = e => {
        this.input_position = e.target.value.split(",");
    }

    gotoInput = e => {
        if(e.keyCode == 13){
            if(this.input_position.length === 2) {
                this.map.setCenter(new kakao.maps.LatLng(Number(this.input_position[0]), Number(this.input_position[1])));
                this.map.setLevel(4)
                this.marker.setPosition(this.map.getCenter());
                this.marker.setMap(this.map);
            }
        } 
    }

    buttonRV = e => {
        if(this.activeRV[0]){
            this.map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW); 
            this.rvContainer.style.display = 'none'; //로드뷰를 넣은 컨테이너를 숨깁니다
            this.rvWrapper.style.display = 'none';
            this.mapWrapper.style.width = '100%';
            this.rvMarker.setMap(null);
            this.map.relayout();
            this.activeRV[0] = false;
        }
        else{
            this.map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);    
            this.rvMarker.setPosition(this.map.getCenter());
            this.rvMarker.setMap(this.map);
            this.activeRV[0] = true;
        }
    }
    
    vpChange = (lat, lng, level = 6) => {
        const moveLatLon = new kakao.maps.LatLng(lat,	lng);
        // 지도 중심을 이동 시킵니다
        this.map.setCenter(moveLatLon);
        this.map.setLevel(level);
    }
    
    openGraph = e => {
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".graph"
        
        var updateGraphPath = this.updateGraphPath
        var map = this.map
        var ZoneData = this.ZoneData
        input.onchange = function (event) {
            var reader = new FileReader();
            reader.readAsText(event.target.files[0],"euc-kr");
            var filename = event.target.files[0].name;
            var Area = filename.split("_")[0].substr(0,4);
            var ZoneNum = Number(filename.split("_")[0].substr(4));
            var zone_llh = []
            for(let i=0; i<ZoneData.length; i++)
            {
                if(Area == ZoneData[i].area)
                {
                    zone_llh = [ZoneData[i].data[2+ZoneNum*2],ZoneData[i].data[2+ZoneNum*2+1]]
                    console.log(zone_llh) 
                }
            }
            // if(Area == "JEJU") {
            //     zone_llh = [ZoneData[0][2+ZoneNum*2],ZoneData[0][2+ZoneNum*2+1]]
            //     console.log(zone_llh)
            // }
            // else if(Area == "SEJN") {
            //     zone_llh = [ZoneData[1][2+ZoneNum*2],ZoneData[1][2+ZoneNum*2+1]]
            //     console.log(zone_llh)
            // }
            // else{
            //     console.log("Invalid Area!!",Area)
            // }

            reader.onload = () => {
                var graph_data = reader.result;
                updateGraphPath(graph_data,zone_llh,map)
            }
        }
        input.click()
    }
    
    updatePolygonPath(data_array) {
        var polygonPath = [];
        var offset = [data_array[0], data_array[1]];
        for (let i = 2; i < this.data_array.length; i=i+2)
        {   
            polygonPath = [ new kakao.maps.LatLng(data_array[i]-offset[0]/2, data_array[i+1]-offset[1]/2),
                            new kakao.maps.LatLng(data_array[i]+offset[0]/2, data_array[i+1]-offset[1]/2),
                            new kakao.maps.LatLng(data_array[i]+offset[0]/2, data_array[i+1]+offset[1]/2),
                            new kakao.maps.LatLng(data_array[i]-offset[0]/2, data_array[i+1]+offset[1]/2)];
            // 지도에 표시할 다각형을 생성합니다
            var polygon = new kakao.maps.Polygon({
                path:polygonPath, // 그려질 다각형의 좌표 배열입니다
                strokeWeight: 3, // 선의 두께입니다
                strokeColor: '#39DE2A', // 선의 색깔입니다
                strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                strokeStyle: 'longdash', // 선의 스타일입니다
                fillColor: '#A2FF99', // 채우기 색깔입니다
                fillOpacity: 0 // 채우기 불투명도 입니다
            });
            
            // 지도에 다각형을 표시합니다
            polygon.setMap(this.map);
            this.zone_grid_line.push(polygon);

            // 커스텀 오버레이에 표시할 내용입니다     
            // HTML 문자열 또는 Dom Element 입니다 
            var content = '<div class ="label"><span class="left"></span><span class="center" style="color:DarkBlue"> ZONE '+(String(i/2)-1)+' </span><span class="right"></span></div>';

            // 커스텀 오버레이가 표시될 위치입니다 
            var position = new kakao.maps.LatLng(data_array[i], data_array[i+1]);  

            // 커스텀 오버레이를 생성합니다
            var customOverlay = new kakao.maps.CustomOverlay({
                position: position,
                content: content   
            });
            
            customOverlay.setMap(this.map)
            this.zone_grid_overlay.push(customOverlay);
        }
    }

    updateGraphPath(graph_data, zone_llh, map){
        var moveLatLon = new kakao.maps.LatLng(zone_llh[0],zone_llh[1]);
        // 지도 중심을 이동 시킵니다
        map.setCenter(moveLatLon);
        map.setLevel(6);
        var buffer = graph_data.toString();
        buffer = buffer.split("\n");
        var var_len = Number(buffer[0]);
        var sensor_num = Number(buffer[var_len+1])
        var start_idx = var_len+sensor_num+2
        var parsed_data = []

        var lat = zone_llh[0]
        var lon = zone_llh[1]
        
        var zone_ecef = geodetic.project(lat, lon, 0.0)

        lat = lat*Math.PI/180
        lon = lon*Math.PI/180

        for(let i=0; i<var_len; i++) {
            var parsed = buffer[start_idx+i].split("\t")
            var x = Number(parsed[6])
            var y = Number(parsed[10])
            var z = Number(parsed[14])
            
            var X = -Math.sin(lon)*x - Math.sin(lat)*Math.cos(lon)*y + Math.cos(lat)*Math.cos(lon)*z + zone_ecef[0]
            var Y = Math.cos(lon)*x  - Math.sin(lat)*Math.sin(lon)*y + Math.cos(lat)*Math.sin(lon)*z + zone_ecef[1]
            var Z =                    Math.cos(lat)*y               + Math.sin(lat)*z               + zone_ecef[2]

            var llh = geodetic.unproject(X,Y,Z)
            parsed_data.push(llh);
        }
        for (let i = 0; i < parsed_data.length; i++){
            var circle = new kakao.maps.Circle({
                center : new kakao.maps.LatLng(parsed_data[i][0], parsed_data[i][1]),  // 원의 중심좌표 입니다 
                radius: 1, // 미터 단위의 원의 반지름입니다 
                strokeWeight: 1, // 선의 두께입니다 
                strokeColor: '#FF0000', // 선의 색깔입니다
                strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                // strokeStyle: 'dashed', // 선의 스타일 입니다
                fillColor: '#CFE7FF', // 채우기 색깔입니다
                fillOpacity: 0.7  // 채우기 불투명도 입니다   
            });

            circle.setMap(map)
        }
    }

    toggleRoadview(position, mapWrapper, map, rvContainer, rv, rvClient, activeRV, rvWrapper){
        
        // var mapWrapper = this.mapWrapper;
        // var map = this.map;
        // var rvContainer = this.rvContainer;
        // var rv = this.rv;
        // 전달받은 좌표(position)에 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄웁니다
        rvClient.getNearestPanoId(position, 50, function(panoId) {
            if (panoId === null || activeRV[0] === false) {
                rvContainer.style.display = 'none'; //로드뷰를 넣은 컨테이너를 숨깁니다
                rvWrapper.style.display = 'none';
                mapWrapper.style.width = '100%';
                map.relayout();
            } else {
                mapWrapper.style.width = '50%';
                map.relayout(); //지도를 감싸고 있는 영역이 변경됨에 따라, 지도를 재배열합니다
                rvContainer.style.display = 'block'; //로드뷰를 넣은 컨테이너를 보이게합니다
                rvWrapper.style.display = 'block';
                rv.setPanoId(panoId, position); //panoId를 통한 로드뷰 실행
                rv.relayout(); //로드뷰를 감싸고 있는 영역이 변경됨에 따라, 로드뷰를 재배열합니다
            }
        });
    }
    toggleZoneData(){
        if(this.state.zone_grid){
            this.zone_grid_line.forEach((zone_line)=>zone_line.setMap(null));
            this.zone_grid_overlay.forEach((zone_overlay)=>zone_overlay.setMap(null));
            this.setState({zone_grid: false});
        } else {
            this.zone_grid_line.forEach((zone_line)=>zone_line.setMap(this.map));
            this.zone_grid_overlay.forEach((zone_overlay)=>zone_overlay.setMap(this.map));
            this.setState({zone_grid: true});
        }
    }

    toogleMeasureDist(){
        if(!this.state.meas_dist){
            this.setState({meas_dist: true});
        }
        else{
            this.setState({meas_dist: false});
            // 지도 위에 선이 표시되고 있다면 지도에서 제거합니다
            this.deleteClickLine();                        
            // 지도 위에 커스텀오버레이가 표시되고 있다면 지도에서 제거합니다
            this.deleteDistnce();
            // 지도 위에 선을 그리기 위해 클릭한 지점과 해당 지점의 거리정보가 표시되고 있다면 지도에서 제거합니다
            this.deleteCircleDot();  
        }
    }
    
    updateView() {
        if (this.map !== undefined) { 
            for(let i = 0; i < this.ZoneList.length; i++){
                this.getData(this.ZoneList[i]);
            }
        }
    }

    render() {
        if (this.map !== undefined) {
            // this.drawPath();
        }
        return (
            <div style={{display:"flex"}}>
            {/* {this.getSideBar()} */}
                <div style={{
                    margin: "auto",
                    width: "90%",
                    padding: "20px 0",
                    textAlign: "center"
                }}>
                    <div style={{
                        width: "100%",
                        margin: "auto",
                        textAlign: "right"
                    }}>
                        
                        <Button color="primary" onClick={this.openGraph}>Load Graph</Button>
                        <Button id="LoadRoad" color="info"> Load Road</Button>
                        <Button id="GoTo" color="info"> Go To</Button>
                        <h4 style={{
                            float: 'left',
                            margin: "auto",
                            textAlign: "left"
                        }}>Zone Grid</h4>
                        
                        <UncontrolledCollapse toggler="#GoTo" >
                            <InputGroup style={{float:"right", maxWidth:"400px", marginTop:"10px"}}>
                                <InputGroupAddon style={{ }} addonType="prepend">
                                    <InputGroupText style={{ width: "100%"}}>Area</InputGroupText>
                                </InputGroupAddon>
                                <Input style={{}} type="select" onChange={this.setGoToArea}>
                                    {Object.values(this.ZoneList).map((name)=><option>{name}</option>)}
                                </Input>
                                <InputGroupAddon style={{ }} addonType="prepend">
                                    <InputGroupText style={{ width: "100%"}}>Zone</InputGroupText>
                                </InputGroupAddon>
                                <Input style={{}} type="search" value={this.state.goto.zone} onChange={this.setGoToZone} />
                                
                                <Button size="md" color={"primary"} style={{float: "right"}} onClick={this.handleGoTo.bind(this, this.state.goto.area, this.state.goto.zone)}>
                                    Go
                                </Button>
                            </InputGroup>
                        </UncontrolledCollapse>

                        <UncontrolledCollapse toggler="#LoadRoad" >
                            <InputGroup style={{float:"right", maxWidth:"400px", marginTop:"10px"}}>
                                <InputGroupAddon style={{ }} addonType="prepend">
                                    <InputGroupText style={{ width: "100%"}}>Area</InputGroupText>
                                </InputGroupAddon>
                                <Input style={{}} type="select" onChange={this.setArea}>
                                    {Object.keys(this.state.zone_list).map((name)=><option>{name}</option>)}
                                </Input>
                                <InputGroupAddon style={{ }} addonType="prepend">
                                    <InputGroupText style={{ width: "100%"}}>Zone</InputGroupText>
                                </InputGroupAddon>
                                <Input style={{}} type="select" onChange={this.setZone}>
                                    <option>all</option>
                                    {/* TODO *** store available zones at list & call */}
                                    {Object.values(this.state.zone_list[this.state.selected_area]).map((name)=><option>{name}</option>)}
                                </Input>
                                <Button size="md" color={"primary"} style={{float: "right"}} onClick={this.handleLoadLRN.bind(this, this.state.selected_area, this.state.selected_zone)}>
                                    Load
                                </Button>
                            </InputGroup>
                        </UncontrolledCollapse>
                    </div>

                    <InputGroup size="lg" style={{display: "inline-flex", alignItems:"center"}}>
                        <InputGroupAddon style={{ }} addonType="prepend">
                            <InputGroupText style={{ width: "100%"}}>위도,경도</InputGroupText>
                        </InputGroupAddon>
                        <Input style={{marginRight: "30%"}}type="search" onKeyUp={this.gotoInput} onChange={this.setInputPosition} />

                        <Button size="sm" color={this.state.zone_grid ? "primary" : "secondary"} style={{float: "right"}} onClick={this.toggleZoneData.bind(this)}> Show Zone</Button>
                        <Button id="dataListToggler" size="sm" color="info" style={{float:"right"}} onClick={this.showDataList.bind(this,!this.state.data_bar_open)}> Show Data</Button>
                    </InputGroup>

                    <div style={{position: "relative", display:"inline-flex", width:"100%", height:"80vh", justifyContent:"space-between"}}>
                        <div id="kakaoWrapper" style={{display: "flex", width:"auto", height: "100%", float:'left', flex: "1"}}>
                            <div id="mapWrapper" style={{width:"100%", height: "100%", }}>
                                <div id="map" style={{ width: "100%", height: "100%" }}></div>
                                <p class="modes">
                                    <Button size="sm" color={this.state.meas_dist ? "primary" : "secondary"} style={{float: "right"}} onClick={this.toogleMeasureDist.bind(this)}> 거리재기</Button>
                                </p>
                            </div>
                            <div id="rvWrapper" style={{width:"50%", height: "100%", display: "none" }}>
                                <div id="roadview" style={{ width: "100%", height: "100%", display: "none" }}></div>
                            </div>
                        </div>  
                        <UncontrolledCollapse id="dataCollapse" className="vertical" toggler="#dataListToggler" style={{textAlign:"left", border:"2px solid #cbcbcb"}}>
                            {this.getDataList()}
                        </UncontrolledCollapse>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ZoneGridPage);
